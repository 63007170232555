import { User } from 'src/models/user';
import http from './http.service';
import http_report from './http.report.service';
import jwtDecode from 'jwt-decode';
import configFile from 'src/config.json';
import { emsApiConfig } from 'src/config';
import { AuthTypeEnum } from '../enums/general.enums';
import { updateEvent } from 'src/slices/calendar';
import { AuthState } from 'src/models/auth';

const apiEndpoint = '/auth/login';
const apiBypassEndpoint = '/auth/bypass';
const apiVerifyEndpoint = '/auth/verify';
const apiSessionEndpoint = '/auth/CheckSession';
const apiLogoutEndpoint = '/auth/Logout';
const tokenKey = 'accessToken';

export async function login(
  username: string,
  password: string
): Promise<AuthState> {
  const { data } = await http.post(apiEndpoint, {
    username,
    password
  });

  let authState: AuthState = {
    authTypeId: 0,
    isAuthenticated: false,
    isInitialized: false,
    isTwoFactorSetup: null,
    QRCode: null,
    userId: null,
    manualCode: null,
    user: null,
    username: null
  };

  if (data.authTypeId === AuthTypeEnum.NO_AUTH) {
    setSession(data.accessToken);
    let user = getUserFromJWTToken(data.accessToken);
    authState.user = user;
    authState.isAuthenticated = true;
    authState.isInitialized = true;
    authState.userId = user.id;
    authState.username = data.username;
  } else {
    authState.user = null;
    authState.isAuthenticated = false;
    authState.isInitialized = true;
    authState.userId = data.userId;
    authState.QRCode =
      data.authenticatorData !== null ? data.authenticatorData.qrCode : null;
    authState.manualCode =
      data.authenticatorData !== null
        ? data.authenticatorData.manualCode
        : null;
    authState.username = data.username;
  }

  authState.authTypeId = data.authTypeId;
  authState.isTwoFactorSetup =
    data.authenticatorData !== null
      ? data.authenticatorData.isTwoFactorSetup
      : null;

  return authState;
}

export async function verify(
  code: string,
  userId: string,
  username: string
): Promise<AuthState> {
  const { data } = await http.post(apiVerifyEndpoint, {
    code,
    userId,
    username
  });

  let authState: AuthState = {
    authTypeId: 0,
    isAuthenticated: false,
    isInitialized: false,
    isTwoFactorSetup: null,
    isSessionEnded: false,
    QRCode: null,
    userId: null,
    manualCode: null,
    user: null
  };

  if (data.accessToken !== null) {
    setSession(data.accessToken);
    let user = getUserFromJWTToken(data.accessToken);
    authState.user = user;
    authState.isAuthenticated = true;
    authState.isInitialized = true;
    authState.userId = user.id;
    authState.username = data.username;
    authState.isSessionEnded = false;
  }

  authState.authTypeId = data.authTypeId;

  return authState;
}

export async function bypass(username: string): Promise<AuthState> {
  const { data } = await http.post(apiBypassEndpoint, {
    username
  });

  let authState: AuthState = {
    authTypeId: 0,
    isAuthenticated: false,
    isInitialized: false,
    isTwoFactorSetup: null,
    isSessionEnded: false,
    QRCode: null,
    userId: null,
    manualCode: null,
    user: null
  };

  if (data.accessToken !== null) {
    setSession(data.accessToken);
    let user = getUserFromJWTToken(data.accessToken);
    authState.user = user;
    authState.isAuthenticated = true;
    authState.isInitialized = true;
    authState.userId = user.id;
    authState.username = data.username;
    authState.isSessionEnded = false;
  }

  authState.authTypeId = data.authTypeId;

  return authState;
}

export const getAuthState = (
  user: User,
  isAuthenticated: boolean,
  isInitialized: boolean,
  isSessionEnded: boolean
): AuthState => {
  let authState: AuthState = {
    authTypeId: 0,
    isAuthenticated: isAuthenticated,
    isInitialized: isInitialized,
    isTwoFactorSetup: null,
    isSessionEnded: isSessionEnded,
    user: user
  };

  return authState;
};

export const getCurrentUser = (): User => {
  let accessToken = localStorage.getItem('accessToken');

  if (accessToken === null) return null;

  return getUserFromJWTToken(accessToken);
};

export const getUserFromJWTToken = (token: string): User => {
  return getUserInfoFromDecodedToken(decodeJwtToken(token));
};

const decodeJwtToken = (token: string): { [key: string]: string } => {
  return jwtDecode(token);
};

const setDefaultHeader = (accessToken) => {
  http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const getUserInfoFromDecodedToken = (token: {
  [key: string]: string;
}): User => {
  const {
    contactId,
    roles,
    statesAssigned,
    fullName,
    title,
    primaryGroupSid,
    groupSid,
    locality,
    country
  } = configFile.userTokenInfo;

  setDefaultHeader(localStorage.getItem('accessToken'));

  return {
    name: token[fullName],
    roles: token[roles],
    jobtitle: token[title],
    id: token[contactId],
    statesAssigned: token[statesAssigned],
    primaryGroupSid: token[primaryGroupSid],
    groupSid: token[groupSid],
    locality: token[locality],
    country: token[country]
  };
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem(tokenKey, accessToken);
    http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    http_report.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(tokenKey);
    delete http.defaults.headers.common.Authorization;
    delete http_report.defaults.headers.common.Authorization;
  }
};

const clearSession = (): void => {
  console.log('Clear session');
  localStorage.removeItem(tokenKey);
  delete http.defaults.headers.common.Authorization;
  delete http_report.defaults.headers.common.Authorization;
};

export const checkUserHasRole = (roleId: number): boolean => {
  let id = roleId === null || roleId === undefined ? null : roleId.toString();
  let userInfo = getCurrentUser();
  if (id === null || userInfo === null) return false;

  return userInfo.roles.includes(id);
};

export const checkTokenExpiry = (token: string): boolean => {
  try {
    const tokenParts = token.split('.'); // Split the token into its parts
    const payload = JSON.parse(atob(tokenParts[1])); // Decode the payload
    if (payload.exp) {
      // Check if expiration time exists
      const expiryTime = payload.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = Date.now(); // Get the current time
      return currentTime >= expiryTime; // Return true if current time is greater than or equal to expiry time
    }
    return true; // Treat as expired if no expiration time is found
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Treat any errors as if the token is expired
  }
};

export async function checkSession(): Promise<boolean> {
  const { data } = await http.get(apiSessionEndpoint);

  return data;
}

export async function logoutUser() {
  await http.get(apiLogoutEndpoint);
}

export default {
  login,
  bypass,
  verify,
  getCurrentUser,
  getUserFromJWTToken,
  setSession,
  checkUserHasRole,
  getAuthState,
  checkTokenExpiry,
  clearSession,
  checkSession,
  logoutUser
};
